/* nav stuff */

.nav_brand_wrapper {
  z-index: 1000;
  position: fixed;
  top: 30px;
  left: 2%;
  transition: 0.5s;
}
.nav_info_wrapper {
  z-index: 1000;
  position: fixed;

  top: 30px;
  right: 2%;
}

.nav_a {
  text-decoration: none;
  font-weight: 300;
  font-size: 1.2rem;
  color: black;
}

.nav_brand {
  letter-spacing: 1px;
  font-family: "Trajen";
}

.nav_brand span {
  font-size: 0.9rem;
}

.nav_info {
  font-family: "SF-PRO";
  letter-spacing: 1px;

  font-weight: 800;
}

.wrapper {
  height: 100vh;
}

.project_title {
  width: fit-content;
  font-family: "SF-PRO";
  position: relative;
  left: 50%;
  top: 4%;
  font-weight: 600;
  transform: translateX(-50%);
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.swiper {
  top: 3%;
  height: 90vh;
}

.slide {
  height: 100%;
  width: 100%;
}

.proj_item {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px;
}

.is_portrait {
  height: 90%;
}

.is_landscape {
  width: 60%;
}

@media screen and (max-width: 1000px) {
  .project_title {
    font-size: 2rem;
    top: 10%;
  }
  .swiper {
    margin-top: 10%;
    height: 80%;
  }
}

@media screen and (max-width: 500px) {
  .nav_a {
    font-size: 1rem;
  }
  .project_title {
    font-size: 1.5rem;
    top: 18%;
  }
  .swiper {
    margin-top: 20%;
    height: 80%;
  }

  .is_landscape {
    width: 95%;
  }

  .is_portrait {
    height: 80%;
  }
}
