.media_wrapper {
  height: 100vh;
}

.project_title {
  width: fit-content;
  font-family: "SF-PRO";
  position: relative;
  left: 50%;
  top: 4%;
  font-weight: 600;
  transform: translateX(-50%);
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.video {
  position: relative;
  left: 50%;
  margin-top: 3%;

  transform: translateX(-50%);
}

.is_port {
  height: 85%;
}

.is_lan {
  width: 70%;
}

@media screen and (max-width: 1000px) {
  .video {
    margin-top: 10%;
  }

  .project_title {
    font-size: 2rem;
    top: 8%;
  }
}
@media screen and (max-width: 500px) {
  .video {
    margin-top: 20%;
  }

  .is_lan {
    width: 95%;
  }

  .is_port {
    height: 80%;
  }
}
