.wrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.hero {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.nav_brand_wrapper {
  z-index: 1000;
  position: fixed;
  top: 30px;
  left: 2%;
  transition: 0.5s;
  mix-blend-mode: difference;
}
.nav_info_wrapper {
  z-index: 1000;
  position: fixed;

  top: 30px;
  right: 2%;
}

.nav_a {
  text-decoration: none;
  font-weight: 300;
  font-size: 1.2rem;
  color: white;
}

.nav_brand {
  letter-spacing: 1px;
  font-family: "Trajen";

  mix-blend-mode: difference;
}

.nav_brand span {
  font-size: 0.9rem;
}

.nav_info {
  font-family: "SF-PRO";
  letter-spacing: 1px;

  font-weight: 800;
}

.caption_wrapper {
  height: 100px;
  position: absolute;
  z-index: 1000;
  top: 33%;
  left: 4%;
  mix-blend-mode: difference;
}

.caption {
  font-family: "SF-PRO";
  color: white;
  font-size: 3.5rem;
}

.mail_wrapper {
  position: absolute;
  bottom: 2%;
  left: 2%;
  mix-blend-mode: difference;
}

.mail {
  font-family: "SF-PRO";
  color: white;
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
}

.insta_wrapper {
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.insta {
  color: white;
  font-size: 4rem;
}

@media screen and (max-width: 850px) {
  .caption_wrapper {
    top: 38%;
  }
  .caption {
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .nav_brand {
    font-size: 1rem;
  }

  .nav_info {
    font-size: 1rem;
  }
  .nav_info_wrapper {
    mix-blend-mode: difference;
  }

  .caption_wrapper {
    top: 36%;
  }
  .caption {
    font-size: 1.7rem;
    line-height: 110%;
  }

  .mail {
    font-size: 1.5rem;
  }

  .insta_wrapper {
    bottom: 1%;
    right: 3%;
  }
  .insta {
    font-size: 3rem;
  }
}
