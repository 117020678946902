@font-face {
  font-family: "SF-PRO";
  src: url("../../Assets/Fonts/SF-Pro-Display-Ultralight.otf");
}
@font-face {
  font-family: "Trajen";
  src: url("../../Assets/Fonts/Trajan\ Pro\ 3\ Regular.otf");
}

#loadingContainer {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#contentContainer {
  padding: 0;
  background-color: white;

  overflow: hidden;
}

#gallary {
  margin-top: 70px;
  padding-bottom: 20px;
}
