/* fonts */
@font-face {
  font-family: "SF-PRO";
  src: url("./Assets/Fonts/SF-Pro-Display-Ultralight.otf");
}
@font-face {
  font-family: "Trajen";
  src: url("./Assets/Fonts/Trajan\ Pro\ 3\ Regular.otf");
}

* {
  padding: 0;
  margin: 0;
}

.body {
  background-color: white;
}

.hidden {
  display: none;
}
