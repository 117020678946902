.loader_img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  filter: brightness(70%);

  z-index: -6;
}

.loader_text_content {
  position: absolute;
  left: 1%;
  top: 1%;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  position: relative;
  left: 3%;
  font-size: 2.1rem;
  color: white;
}

.enter {
  letter-spacing: 2px;
  font-family: "SF-PRO";
  position: absolute;
  z-index: 100;
  color: white;
  left: 50%;
  bottom: 42%;
  transform: translateX(-50%);
  transition: 1s;
  font-size: 1.3rem;
  font-weight: 100;
  cursor: default;
}

/* content styles */

.nav_brand_wrapper {
  z-index: 1000;
  position: fixed;
  top: 30px;
  left: 2%;
  opacity: 0;
  transition: 0.5s;
}
.nav_info_wrapper {
  z-index: 1000;
  position: fixed;
  top: 30px;
  right: 2%;
}

.nav_a {
  color: white;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.2rem;
}

.nav_brand {
  letter-spacing: 1px;
  font-family: "Trajen";
}

.nav_brand span {
  font-size: 0.9rem;
}

.nav_info {
  font-family: "SF-PRO";
  letter-spacing: 1px;
}

.nav_left {
  padding-left: 2%;
  font-family: "Trajen";
}
.nav_right {
  text-align: right;
  font-family: "SF-PRO";
  padding-right: 2%;
}

.hero_video {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 100vh;
  flex-direction: column;
  background-color: white;
}
.hero_vid {
  position: relative;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
}

.hero {
  position: relative;
}

.mute_button {
  position: absolute;
  bottom: 30px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
}

.mute_button i {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.4rem;
}

.content_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mute_button {
  position: absolute;
  bottom: 30px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
}

.mute_button .mute_icon {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.4rem;
}

/* gallary stuff */
.row {
  position: relative;
  padding-bottom: 40px;
  padding-top: 40px;
  margin-bottom: 30px;
  display: grid;
}

.video_title {
  font-family: "SF-PRO";
  display: none;
}

#row1 {
  grid-template-columns: 60% 40%;
}

#row2 {
  grid-template-columns: 25% 50% 25%;
}

#row3 {
  grid-template-columns: 1fr;
}

#row4 {
  grid-template-columns: 40% 60%;
}
#row5 {
  grid-template-columns: 1fr 1fr 1fr;
}

#row6 {
  grid-template-columns: 1fr;
}

#row7 {
  grid-template-columns: 35% 30% 35%;
}

#row8 {
  grid-template-columns: 40% 60%;
}

.gallary_video {
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px; */
  position: relative;
  cursor: none;
  opacity: 0;
  transition: 1.3s;
}

.gallary_img {
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px; */
  position: relative;
  cursor: none;
  opacity: 0;
  transition: 1.3s;
}

/* row1 */
#vidJlAw {
  width: 70%;
  top: 50%;
  transform: translateY(-50%);
  left: 23%;
}

#vidFlSummer {
  width: 55%;
  left: 10%;
}

/* row 2 */
#vidBalencTest {
  width: 80%;
  left: 100%;
  transform: translateX(-100%);
}

#vidjlwb {
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#img01525 {
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
}

/* row3 */
#vidGivenchi {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}

/* row 4 */

#vidAllSaints {
  width: 85%;
  top: 50%;
  transform: translatey(-50%);
  left: 22.5%;
}
#vidJlBeauty {
  width: 70%;
  left: 15%;
}

/* row 5 */

#vidNorthenWeather {
  width: 80%;
  left: 20%;
}

#vidPreawZBrights {
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#vidPeipei {
  width: 80%;
}

/* row 6 */
#vidReiss {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}

/* row 7 */

#vid24Miami {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

#vidLulu {
  width: 120%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#vidJeanViv {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

/* row 8 */
#vidAln {
  width: 55%;
  left: 30%;
}

#imgGucci {
  width: 70%;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.cursor_wrap {
  position: fixed;
  max-height: 40px;
  max-width: 180px;
  transform: translate(-50%, -100%);
  z-index: 500;
  pointer-events: none;
  mix-blend-mode: difference;
  text-align: center;
}

.mouse {
  font-family: "SF-PRO";
  color: white;
  display: none;
  font-size: 18px;
}

/* footer */

.footer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.mail_wrapper {
  flex-basis: 50%;
  padding-left: 2%;
}

.mail {
  font-family: "SF-PRO";
  color: black;
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
}

.insta_wrapper {
  padding-right: 2%;
  text-align: right;
  flex-basis: 50%;
}

.insta {
  color: black;
  font-size: 4rem;
}

@media screen and (min-width: 3000px) {
  .logo {
    width: 1000px;
    height: auto;
  }

  .content_logo {
    width: 1000px;
    height: auto;
  }

  .mute_button {
    bottom: 50px;
    right: 70px;
  }

  .mute_button .mute_icon {
    font-size: 3rem;
  }
}

@media screen and (max-width: 900px) {
  .row {
    position: static;
    margin: 0;
    padding: 0;
  }

  .video_title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    display: block;
    align-self: flex-start;
    left: 5%;
  }
  #row1 {
    grid-template-columns: 1fr;
  }

  #row2 {
    grid-template-columns: 1fr;
  }

  #row4 {
    grid-template-columns: 1fr;
  }
  #row5 {
    grid-template-columns: 1fr;
  }

  #row7 {
    grid-template-columns: 1fr;
  }
  #row8 {
    grid-template-columns: 1fr;
  }

  .vid_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .gallary_video,
  .gallary_img {
    position: static;
  }

  .vid_wrapper .video_link {
    display: contents;
  }

  /* row1 */
  #vidJlAw {
    width: 90%;
    transform: translateY(0%);
  }

  #vidFlSummer {
    width: 90%;
  }

  /* row 2 */
  #vidBalencTest {
    width: 90%;
    transform: translateX(0%);
  }

  #vidjlwb {
    width: 90%;
    transform: translate(0%, 0%);
  }
  #img01525 {
    transform: translateY(0%);
    width: 90%;
  }

  /* row 3 */
  #vidGivenchi {
    width: 90%;
    transform: translateX(0%);
  }

  /* row 4 */

  #vidAllSaints {
    width: 90%;
    transform: translatey(0%);
  }
  #vidJlBeauty {
    width: 90%;
  }

  /* row 5 */

  #vidNorthenWeather {
    width: 90%;
  }

  #vidPreawZBrights {
    width: 90%;
    transform: translate(0%, 0%);
  }

  #vidPeipei {
    width: 90%;
  }

  /* row 6 */
  #vidReiss {
    width: 90%;
    transform: translateX(0%);
  }

  /* row 7 */

  #vid24Miami {
    width: 90%;
    transform: translateY(0%);
  }

  #vidLulu {
    width: 90%;
    transform: translateY(0%);
  }

  #vidJeanViv {
    width: 90%;
    transform: translateY(0%);
  }

  /* row 8 */

  #vidAln {
    width: 90%;
  }

  #imgGucci {
    width: 90%;
    transform: translateY(0%);
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 50%;
  }

  .navbar {
    height: 50px;
  }

  .nav_left {
    padding-left: 7%;
  }
  .nav_right {
    padding-right: 7%;
  }
  .navbar .nav_a {
    color: white;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .nav_brand span {
    font-size: 0.6rem;
  }

  .enter {
    font-size: 1rem;
  }
  .content_logo {
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .mail {
    font-size: 1.5rem;
  }

  .insta {
    font-size: 3rem;
  }
}
